<template>
  <div class="new-simulator">
    <v-row>
      <v-hover v-slot="{ hover }">
        <v-btn
          :to="'Simulator'"
          color="white--text"
          :style="{ 'background-color': hover ? '#002147' : '#535353' }"
          style="text-transform: unset !important"
          ><v-icon left> mdi-arrow-left </v-icon
          >{{ $t("planogramSimulations") }}
        </v-btn>
      </v-hover>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="4" class="ml-n2">
        <a-text-field
          label="Simulator Name"
          v-model="$v.diagramName.$model"
          :validator="$v.diagramName"
        >
        </a-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-card
        class="pa-10"
        style="width: 100%; border-radius: 30px"
        elevation="0"
      >
        <!-- Planogram -->
        <div class="planogram">
          <div class="planogram-grid d-flex align-center;">
            <div class="tabs">
              <div class="tab">
                <input type="radio" id="rd3" name="rd" checked="true" />
                <label class="tab-label" for="rd3">{{
                  $t("drawingArea")
                }}</label>
                <div class="tab-content">
                  <div id="ShelvesPalette" class="shelves-palette"></div>
                </div>
              </div>
              <div class="tab">
                <input type="radio" id="rd2" name="rd" />
                <label class="tab-label" for="rd2">{{ $t("entryExit") }}</label>
                <div class="tab-content">
                  <div id="EntryExitPalette" class="entry-exit-palette"></div>
                </div>
              </div>
              <div class="tab">
                <input type="radio" id="rd1" name="rd" />
                <label class="tab-label" for="rd1">{{
                  $t("categories")
                }}</label>
                <div class="tab-content">
                  <div id="myPaletteSmall" class="categories-palette"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="myDiagramDiv"
            style="flex-grow: 1; height: 500px; border: solid 1px #9b9b9b"
          ></div>
        </div>
        <!-- !Planogram -->
      </v-card>
    </v-row>
    <!-- Buttons -->
    <v-row
      class="d-flex justify-start mt-4"
      v-if="$store.getters['auth/getUser']['type']['id'] == 2"
    >
      <v-hover v-slot="{ hover }">
        <v-btn
          fab
          x-small
          outlined
          :color="hover ? 'primary' : '#424242'"
          class="mx-2"
          style="margin-top: 7px"
          @click.stop="helpDialog = true"
        >
          <v-icon> mdi-help </v-icon>
        </v-btn>
      </v-hover>
      <v-hover v-slot="{ hover }">
        <v-btn
          @click.stop="newCategoryDialog = true"
          color="white--text"
          :style="{ 'background-color': hover ? '#002147' : '#535353' }"
          style="text-transform: unset !important"
          class="ml-6 mt-1"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ $t("addCategory") }}
        </v-btn>
      </v-hover>
      <v-btn
        @click="createSimulator"
        color="primary"
        class="ml-auto mt-1"
        style="text-transform: unset !important"
        >{{ $t("create") }}</v-btn
      >
    </v-row>
    <!-- !Buttons -->
    <!-- Products Dialog -->
    <v-dialog
      v-model="$store.getters['planogram/productsDialog']"
      width="700"
      @click:outside="$store.dispatch('planogram/hideProductsDialog')"
      style="color: black"
    >
      <v-card>
        <v-card-title>
          {{ $store.getters["planogram/name"] }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-btn
            color="primary"
            small
            outlined
            class="mb-4"
            @click="setColorDialog = true"
            style="text-transform: unset !important"
            v-if="$store.getters['auth/getUser']['type']['id'] == 2"
          >
            {{ $t("setColor") }}
            <v-icon right small>mdi-palette</v-icon>
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="$store.getters['planogram/products']"
            :items-per-page="4"
            class="elevation-2"
          >
          </v-data-table>
        </v-card-text>
        <center class="px-6">
          <planogramProductsView
            v-if="$store.getters['planogram/productsDialog']"
            :category="$store.getters['planogram/category']"
            style="width: 100%"
          />
        </center>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$store.dispatch('planogram/hideProductsDialog')"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Products Dialog -->
    <!-- Set Color Dialog -->
    <v-dialog
      v-model="setColorDialog"
      width="550"
      @click:outside="closeSetColorDialog()"
    >
      <v-card>
        <v-card-title>
          {{
            $t("setColorOfCategory", {
              1: $store.getters["planogram/name"],
            })
          }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-color-picker
            dot-size="25"
            mode="hexa"
            v-model="colorCategoryComputed"
          ></v-color-picker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeSetColorDialog()"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            @click="updateCategoryColor"
            style="text-transform: unset !important"
          >
            {{ $t("set") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Set Color Dialog -->
    <!-- Help Dialog -->
    <v-dialog
      v-model="helpDialog"
      width="550"
      @click:outside="helpDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5"> {{ $t("help") }} </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("function") }}</th>
                  <th class="text-left">{{ $t("key") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in controls" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.key }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="helpDialog = false"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !Help Dialog -->
    <!-- New Category Dialog -->
    <v-dialog
      v-model="newCategoryDialog"
      width="750"
      @click:outside="closeNewCategoryDialog"
    >
      <v-card>
        <v-card-title class="text-h5"> {{ $t("newCategory") }} </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="6">
              <a-text-field
                :label="$t('code') + $t('required')"
                v-model="$v.categoryCode.$model"
                :validator="$v.categoryCode"
              >
              </a-text-field>
            </v-col>
            <v-col cols="6" class="ml-n3">
              <a-text-field
                :label="$t('name') + $t('required')"
                v-model="$v.categoryName.$model"
                :validator="$v.categoryName"
              >
              </a-text-field>
            </v-col>
          </v-row>
          <v-col cols="6" class="mt-3 ml-n3">
            <v-btn
              color="primary"
              small
              outlined
              @click="newProductCategoryDialog = true"
              style="text-transform: unset !important"
              >{{ $t("addProduct") }}
              <v-icon right> mdi-plus </v-icon>
            </v-btn>
          </v-col>

          <v-data-table
            :headers="headers"
            :items="this.newCategoryProducts"
            :items-per-page="4"
            class="elevation-2 mt-2"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeNewCategoryDialog"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            @click="createCategory"
            style="text-transform: unset !important"
          >
            {{ $t("create") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !New Category Dialog -->
    <!-- New Product Category Dialog -->
    <v-dialog
      v-model="newProductCategoryDialog"
      width="550"
      @click:outside="closeProductCategoryDialog"
    >
      <v-card>
        <v-card-title class="text-h5"> {{ $t("addProduct") }} </v-card-title>
        <v-divider />

        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="6">
              <a-text-field
                :label="$t('name') + $t('required')"
                v-model="$v.inputProductName.$model"
                :validator="$v.inputProductName"
              >
              </a-text-field>
            </v-col>
            <v-col cols="6">
              <a-text-field
                :label="$t('sku') + $t('required')"
                v-model="$v.inputProductSKU.$model"
                :validator="$v.inputProductSKU"
              >
              </a-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeProductCategoryDialog"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            @click="createProductCategory"
            style="text-transform: unset !important"
          >
            {{ $t("add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !New Product Category Dialog -->
  </div>
</template>
<style lang="scss" scoped>
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  color: #535353;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #dddddd;
  cursor: pointer;

  &:first-of-type {
    margin-bottom: 8px;
  }
}

.tab-label:hover {
  background: #535353;
  color: white;
}

.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.tab-content {
  max-height: 0;
  color: #535353;
  background: white;
}

input:checked + .tab-label {
  background: #535353;
  color: white;
}

input:checked + .tab-label::after {
  transform: rotate(90deg);
}

input:checked ~ .tab-content {
  max-height: 100vh;
}

input[type="radio"] + label:before {
  display: none;
}

/* !Accordion styles */
.planogram {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.planogram-grid {
  width: 245px;
  margin-right: 2px;
  background-color: white;
  border: solid 1px #9b9b9b;
}

.shelves-palette {
  width: 245px;
  height: 120px;
}

.entry-exit-palette {
  width: 245px;
  height: 80px;
}

.categories-palette {
  width: 244px;
  height: 370px;
}

.v-dialog {
  overflow-x: hidden;
}
</style>
<script>
// @ is an alias to /src
import * as go from "gojs";
import store from "../store";
import ATextField from "../components/ATextField.vue";
import AppError from "@/utils/AppError.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import planogramProductsView from "@/components/PlanogramProductsView.vue";

// Default settings to build the planogram.
let AllowTopLevel = false;
let CellSize = new go.Size(50, 50);
let myDiagram = null;
let myPaletteSmall = null;

export default {
  name: "new-simulator",

  components: {
    planogramProductsView,
    ATextField,
  },

  mixins: [validationMixin],

  validations: {
    // Form validations
    diagramName: { required },
    categoryName: { required },
    categoryCode: { required },
    inputProductName: { required },
    inputProductSKU: { required },
    inputAisle: { required },
    inputSide: { required },
  },

  mounted: function () {
    this.getStore();
    this.getLoadedPlanogram();
  },

  computed: {
    // Computed property to get and set the color of each product
    colorCategoryComputed: {
      get: function () {
        return this.$store.getters["planogram/category"].color;
      },
      set: function (newValue) {
        this.newColor = newValue;
      },
    },

    getStoreUpload() {
      return this.categories ? this.categories.length : null;
    },
    getStoreFiles() {
      return this.store ? this.store.files.length : null;
    },

    storesArraySorted: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      if (this.storesImport) {
        return this.storesImport.concat().sort(compare);
      } else {
        return this.storesImport;
      }
    },
  },

  data() {
    return {
      categories: null,
      loadedDiagram: null,
      headers: [
        {
          text: this.$t("description"),
          align: "start",
          value: "description",
        },
        {
          text: this.$t("sku"),
          value: "SKU",
        },
      ],
      products: [],
      controls: [
        {
          name: "Zoom In",
          key: "CTRL + SCROLL UP",
        },
        {
          name: "Zoom out",
          key: "CTRL + SCROLL DOWN",
        },
        {
          name: "Undo",
          key: "CTRL + Z",
        },
        {
          name: "Redo",
          key: "CTRL + Y",
        },
      ],

      // Dialogs
      helpDialog: false,
      newCategoryDialog: false,
      newProductCategoryDialog: false,
      setColorDialog: false,

      categoryName: null,
      categoryCode: null,
      newCategoryProducts: [],
      inputProductName: null,
      inputProductSKU: null,

      store: null,
    };
  },

  methods: {
    /**
     * Update category color.
     * @public
     */
    updateCategoryColor() {
      if (this.newColor) {
        var node = myPaletteSmall.model.findNodeDataForKey(
          this.$store.getters["planogram/category"].key
        );
        if (node) {
          this.$store.dispatch("planogram/removeCategory");
          myPaletteSmall.model.setDataProperty(node, "color", this.newColor);
          this.$store.dispatch("planogram/addCategory", node);
        } else {
          var nodeS = myDiagram.model.findNodeDataForKey(
            this.$store.getters["planogram/category"].key
          );
          this.$store.dispatch("planogram/removeCategory");
          myDiagram.model.setDataProperty(nodeS, "color", this.newColor);
          this.$store.dispatch("planogram/addCategory", nodeS);
        }
      }

      let color = this.$store.getters["planogram/category"].color;

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.categoriesAction;
      url = url.replace(":id", this.store.id);
      url = url.replace(":code", this.$store.getters["planogram/category"].key);
      this.$api({
        url: url,
        data: {
          color: color,
        },
        method: "PUT",
      })
        .then(() => {
          this.closeSetColorDialog();
          this.$store.dispatch(
            "alert/showSuccess",
            this.$t("categoryColorUpdated")
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Create the simulator.
     * @public
     */
    createSimulator() {
      this.$v.diagramName.$touch();
      if (this.$v.diagramName.$invalid) {
        return;
      }

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        data: {
          name: this.diagramName,
          planogram: myDiagram.model.toJson(),
        },
        method: "POST",
      })
        .then(() => {
          this.$store.dispatch(
            "alert/showSuccess",
            this.$t("simulatorCreated")
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedPlanogram() {
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedDiagram = resp.data.planogram;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.getCategories();
        });
    },
    /**
     * Create category.
     * @public
     */
    createCategory() {
      this.$v.categoryName.$touch();
      this.$v.categoryCode.$touch();
      if (this.$v.categoryName.$invalid || this.$v.categoryCode.$invalid) {
        return;
      }
      if (this.newCategoryProducts.length == 0) {
        this.$store.dispatch(
          "alert/showWarning",
          this.$t("newCategoryProductWarning")
        );
        return;
      }

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.categories;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        data: {
          code: this.categoryCode,
          category: this.categoryName,
          products: this.newCategoryProducts,
        },
        method: "POST",
      })
        .then(() => {
          this.$store.dispatch("alert/showSuccess", this.$t("categoryCreated"));
          myPaletteSmall.model.addNodeData({
            key: this.categoryCode,
            name: this.categoryName,
            color: "#FFFFFF",
            size: "150 50",
            products: this.newCategoryProducts,
            category: "normal",
          });
          this.closeNewCategoryDialog();
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Close new category dialog.
     * @public
     */
    closeNewCategoryDialog() {
      this.$v.categoryName.$reset();
      this.$v.categoryCode.$reset();
      this.categoryCode = null;
      this.categoryName = null;
      this.newCategoryDialog = false;
      this.newCategoryProducts = [];
    },
    closeSetColorDialog() {
      this.setColorDialog = false;
      this.newColor = null;
    },
    /**
     * Create products in new category.
     * @public
     */
    createProductCategory() {
      this.$v.inputProductName.$touch();
      this.$v.inputProductSKU.$touch();
      if (
        this.$v.inputProductName.$invalid ||
        this.$v.inputProductSKU.$invalid
      ) {
        return;
      }

      this.newCategoryProducts.push({
        description: this.inputProductName,
        SKU: this.inputProductSKU,
      });

      this.closeProductCategoryDialog();
    },
    /**
     * Close product category dialog.
     * @public
     */
    closeProductCategoryDialog() {
      this.$v.inputProductName.$reset();
      this.$v.inputProductSKU.$reset();
      this.$v.inputAisle.$reset();
      this.$v.inputSide.$reset();
      this.inputProductName = null;
      this.inputProductSKU = null;
      this.inputAisle = null;
      this.inputSide = null;
      this.newProductCategoryDialog = false;
    },
    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },
    /**
     * Get categories.
     * @public
     */
    getCategories() {
      let url = this.$config.api.route.categories;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.categories = resp.data;
          this.categoriesPlanogram = [...this.categories];
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.buildPlanogram();
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Build planogram inside 'myDiagramDiv'.
     * @public
     */
    buildPlanogram() {
      const $ = go.GraphObject.make;

      myDiagram = $(go.Diagram, "myDiagramDiv", {
        grid: $(
          go.Panel,
          "Grid",
          { gridCellSize: CellSize, background: "white" },
          $(go.Shape, "LineH", { stroke: "lightgray" }),
          $(go.Shape, "LineV", { stroke: "lightgray" })
        ),
        "draggingTool.isGridSnapEnabled": true,
        "draggingTool.gridSnapCellSpot": go.Spot.Center,
        "resizingTool.isGridSnapEnabled": true,
        "animationManager.isEnabled": false,
        "undoManager.isEnabled": true,
      });

      if (this.loadedDiagram) {
        // Getting data from json
        myDiagram.model = go.Model.fromJson(this.loadedDiagram);
        myDiagram.model.nodeDataArray.forEach((nodeS) => {
          this.categoriesPlanogram.forEach((category, index) => {
            if (nodeS.key === category.code) {
              this.categoriesPlanogram.splice(index, 1);
            }
          });
        });
      }

      myDiagram.commandHandler.doKeyDown = function () {
        // must be a function, not an arrow =>
        var e = this.diagram.lastInput;
        var key = e.key;
        var selectedNode = myDiagram.selection.first();
        var node = selectedNode.data;
        if (
          (key === "Del" && node.products) ||
          (key === "Backspace" && node.products)
        )
          myPaletteSmall.model.addNodeData({
            key: selectedNode.data.key,
            name: selectedNode.data.name,
            color: selectedNode.data.color,
            size: "150 50",
            products: selectedNode.data.products,
            category: "normal",
          });
        // call base method with no arguments (default functionality)
        go.CommandHandler.prototype.doKeyDown.call(this);
      };

      // Regular Nodes represent items to be put onto racks.

      // Normal template used for category and entry/exit nodes.
      var normalTemplate = $(
        go.Node,
        "Auto",
        {
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          doubleClick: function (e, node) {
            // Verify if a node is entry or exit and don't show the modal, otherwise show modal with products.
            if (node.data.key == "Entry" || node.data.key == "Exit") {
              return;
            } else {
              if (node.data.key === store.getters["planogram/category"].key) {
                node.data.color = store.getters["planogram/category"].color;
              }
              store.dispatch("planogram/showProductsDialog");
              store.dispatch("planogram/addCategory", node.data);
            }
          },

          mouseEnter: function (e, node) {
            if (store.getters["planogram/category"]) {
              if (node.data.key === store.getters["planogram/category"].key) {
                myDiagram.model.nodeDataArray.forEach(function (nodeS) {
                  if (node.key === nodeS.key) {
                    myDiagram.model.setDataProperty(
                      nodeS,
                      "color",
                      store.getters["planogram/category"].color
                    );
                  }
                });
              }
            }
          },

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        // Always save/load the point that is the top-left corner of the node.
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            stroke: "#9b9b9b",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        ),
        $(
          go.TextBlock,
          { alignment: go.Spot.Center, font: "bold 14px sans-serif" },
          new go.Binding("text", "key")
        )
      );
      // end Node

      // Heatmap Template used for heatmap nodes.
      var heatMapTemplate = $(
        go.Node,
        "Auto",
        {
          resizeObjectName: "HEATMAP",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        )
      );

      var templmap = new go.Map();

      // Add all templates to diagram.
      templmap.add("normal", normalTemplate);
      templmap.add("heatmap", heatMapTemplate);
      templmap.add("", myDiagram.nodeTemplate);

      myDiagram.nodeTemplateMap = templmap;

      // Groups represent racks where items (Nodes) can be placed.
      // Groups provide feedback when the user drags nodes onto them.
      function highlightGroup(grp, show) {
        if (!grp) return false;
        // Check that the drop may really happen into the Group.
        var tool = grp.diagram.toolManager.draggingTool;
        grp.isHighlighted = show && grp.canAddMembers(tool.draggingParts);
        return grp.isHighlighted;
      }

      var groupFill = "rgb(115, 115, 115, 0.4)";
      var groupStroke = "gray";
      var dropFill = "rgba(128,255,255,0.2)";
      var dropStroke = "blue";

      myDiagram.groupTemplate = $(
        go.Group,
        {
          layerName: "Background",
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),
        },
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),
        {
          // What to do when a drag-over or a drag-drop occurs on a Group.
          mouseDragEnter: function (e, grp) {
            if (!highlightGroup(grp, true))
              e.diagram.currentCursor = "not-allowed";
            else e.diagram.currentCursor = "";
          },
          mouseDragLeave: function (e, grp) {
            highlightGroup(grp, false);
          },
          mouseDrop: function (e, grp) {
            var ok = grp.addMembers(grp.diagram.selection, true);
            if (!ok) grp.diagram.currentTool.doCancel();
          },
        },
        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: groupFill,
            stroke: groupStroke,
            minSize: new go.Size(CellSize.width, CellSize.height),
          },
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          ),
          new go.Binding("fill", "isHighlighted", function (h) {
            return h ? dropFill : groupFill;
          }).ofObject(),
          new go.Binding("stroke", "isHighlighted", function (h) {
            return h ? dropStroke : groupStroke;
          }).ofObject()
        )
      );

      // Decide what kinds of Parts can be added to a Group
      myDiagram.commandHandler.memberValidation = function (grp, node) {
        // cannot add Groups to Groups
        if (grp instanceof go.Group && node instanceof go.Group) return false;
        return true;
      };

      // What to do when a drag-drop occurs in the Diagram's background
      myDiagram.mouseDragOver = function (e) {
        if (!AllowTopLevel) {
          // OK to drop a group anywhere or any Node that is a member of a dragged Group
          var tool = e.diagram.toolManager.draggingTool;
          if (
            !tool.draggingParts.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel &&
                  tool.draggingParts.contains(p.containingGroup))
              );
            })
          ) {
            e.diagram.currentCursor = "not-allowed";
          } else {
            e.diagram.currentCursor = "";
          }
        }
      };

      myDiagram.mouseDrop = function (e) {
        if (AllowTopLevel) {
          // When the selection is dropped in the diagram's background,
          // Make sure the selected Parts no longer belong to any Group
          if (
            !e.diagram.commandHandler.addTopLevelParts(
              e.diagram.selection,
              true
            )
          ) {
            e.diagram.currentTool.doCancel();
          }
        } else {
          // Disallow dropping any regular nodes onto the background, but allow dropping "racks",
          // Including any selected member nodes
          if (
            !e.diagram.selection.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel && p.containingGroup.isSelected)
              );
            })
          ) {
            e.diagram.currentTool.doCancel();
          }
        }
      };

      // Create array with category and its products to create nodes.
      var categories = [];
      if (this.categoriesPlanogram) {
        this.categoriesPlanogram.map((category) => {
          var categoryProducts = [];

          if (category.products != null) {
            category.products.map((product) => {
              categoryProducts.push(product);
            });
          }

          var newCategory = {
            key: category.code,
            name: category.name,
            color: category.color,
            size: "150 50",
            products: categoryProducts,
            category: "normal",
          };

          myDiagram.model.nodeDataArray.forEach(function (node) {
            if (newCategory.key === node.key) {
              myDiagram.model.setDataProperty(node, "color", newCategory.color);
            }
          });

          categories.push(newCategory);
        });
      }

      // Compare function to sort alphabetically the categories.
      function compare(a, b) {
        if (a.key < b.key) {
          return -1;
        }
        if (a.key > b.key) {
          return 1;
        }
        return 0;
      }

      // Sort categories
      categories.sort(compare);

      myPaletteSmall = $(go.Palette, "myPaletteSmall", {
        nodeTemplateMap: myDiagram.nodeTemplateMap,
        groupTemplate: myDiagram.groupTemplate,
      });

      // Specify the contents of the Palette
      myPaletteSmall.model = new go.GraphLinksModel(categories);

      let EntryExitPalette = $(go.Palette, "EntryExitPalette", {
        nodeTemplateMap: myDiagram.nodeTemplateMap,
        groupTemplate: myDiagram.groupTemplate,
      });

      // Specify the contents of the Palette
      EntryExitPalette.model = new go.GraphLinksModel([
        {
          key: this.$t("entry"),
          color: "#C5DDAF",
          size: "50 50",
          category: "normal",
        },
        {
          key: this.$t("exit"),
          color: "#DDBAB9",
          size: "50 50",
          category: "normal",
        },
      ]);

      let ShelvesPalette = $(go.Palette, "ShelvesPalette", {
        nodeTemplateMap: myDiagram.nodeTemplateMap,
        groupTemplate: myDiagram.groupTemplate,
      });

      // Specify the contents of the Palette
      ShelvesPalette.model = new go.GraphLinksModel([
        { key: "Shelf", isGroup: true, size: "100 100", category: "normal" },
      ]);
    },
  },
};
</script>
